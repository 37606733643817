var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gmb-accounts-id"},[_c('screen-header',{attrs:{"title":_vm.$t('gmb.list.id.title'),"breadcrumbs":_vm.breadcrumbs,"is-loading":_vm.isLoading,"back-button":""}}),(_vm.isError)?_c('screen-error',{attrs:{"route":{ name: 'Accounts' },"label":_vm.$t('locationsGmb.id.error.button.back')}}):_vm._e(),_c('screen-container',{attrs:{"direction":"column"}},[_c('screen-tabs',{attrs:{"tabs":_vm.tabs,"element-fixed":false,"current-tab":_vm.currentTab,"is-loading":_vm.isLoading},on:{"click":_vm.setCurrentTab}}),_c('screen-grid',{attrs:{"full-height":true}},[_c('screen-card',{attrs:{"full-height":true,"ratio":"1-1"},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.currentTab === 'admin')?[_c('div',{staticClass:"gmb-accounts-id__table"},[_c('ui-data-table',{attrs:{"columns":[
                  {
                    label: _vm.$t('gmb.list.id.admin.columns.adminName'),
                    field: 'admin',
                  },
                  {
                    label: _vm.$t('gmb.list.id.admin.columns.role'),
                    field: 'role',
                  } ],"rows":_vm.printableAdmins,"total-rows":_vm.printableAdmins.length,"is-loading":_vm.isLoading,"with-pagination":false}})],1)]:_vm._e(),(_vm.currentTab === 'notification')?[_c('div',{staticClass:"gmb-accounts-id__subtitle"},[_c('span',[_c('span',{staticClass:"gmb-accounts-id__subtitle__label"},[_vm._v(_vm._s(_vm.$t('gmb.list.id.notification.topicName')))]),_vm._v(" "+_vm._s(_vm.gmbAccount.notifications.topicName)+" ")]),(_vm.printableNotificationTypes.length === 0)?_c('ui-button',{attrs:{"label":_vm.$t('gmb.list.id.notification.enable'),"button":"cta","variant":"data"},on:{"click":_vm.activateAndLoadNotifications}}):_vm._e()],1),_c('div',{staticClass:"gmb-accounts-id__table"},[_c('ui-data-table',{attrs:{"columns":[
                  {
                    label: _vm.$t('gmb.list.id.notification.columns.notificationTypes'),
                    field: 'notificationType',
                  } ],"rows":_vm.printableNotificationTypes,"total-rows":_vm.printableNotificationTypes.length,"is-loading":_vm.isLoading,"with-pagination":false}})],1)]:_vm._e()]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }