<template>
  <div class="gmb-accounts-id">
    <screen-header :title="$t('gmb.list.id.title')" :breadcrumbs="breadcrumbs" :is-loading="isLoading" back-button />
    <screen-error v-if="isError" :route="{ name: 'Accounts' }" :label="$t('locationsGmb.id.error.button.back')" />
    <screen-container direction="column">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="false"
        :current-tab="currentTab"
        :is-loading="isLoading"
        @click="setCurrentTab"
      />
      <screen-grid :full-height="true">
        <screen-card :full-height="true" ratio="1-1">
          <template v-slot:body>
            <template v-if="currentTab === 'admin'">
              <div class="gmb-accounts-id__table">
                <ui-data-table
                  :columns="[
                    {
                      label: $t('gmb.list.id.admin.columns.adminName'),
                      field: 'admin',
                    },
                    {
                      label: $t('gmb.list.id.admin.columns.role'),
                      field: 'role',
                    },
                  ]"
                  :rows="printableAdmins"
                  :total-rows="printableAdmins.length"
                  :is-loading="isLoading"
                  :with-pagination="false"
                />
              </div>
            </template>
            <template v-if="currentTab === 'notification'">
              <div class="gmb-accounts-id__subtitle">
                <span>
                  <span class="gmb-accounts-id__subtitle__label">{{ $t('gmb.list.id.notification.topicName') }}</span>
                  {{ gmbAccount.notifications.topicName }}
                </span>
                <ui-button
                  :label="$t('gmb.list.id.notification.enable')"
                  v-if="printableNotificationTypes.length === 0"
                  @click="activateAndLoadNotifications"
                  button="cta"
                  variant="data"
                />
              </div>
              <div class="gmb-accounts-id__table">
                <ui-data-table
                  :columns="[
                    {
                      label: $t('gmb.list.id.notification.columns.notificationTypes'),
                      field: 'notificationType',
                    },
                  ]"
                  :rows="printableNotificationTypes"
                  :total-rows="printableNotificationTypes.length"
                  :is-loading="isLoading"
                  :with-pagination="false"
                />
              </div>
            </template>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenError from '@/components/Screen/Error.vue'
import { getAccountNotifications, activateAndGetNotifications } from '@/services/gmb.service'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiButton from '@/components/UI/Button.vue'

const REQUEST_STATUS = {
  success: 'success',
  idle: 'idle',
  error: 'error',
  loading: 'loading',
}

export default {
  name: 'LocationsList',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    ScreenTabs,
    ScreenError,
    UiDataTable,
    UiButton,
  },
  mounted() {
    this.loadNotifications()
  },
  data() {
    return {
      status: 'idle',
      gmbAccount: {},
      currentTab: 'admin',
    }
  },
  computed: {
    ...mapState({
      currentClientId: state => state.client.currentClient.id,
    }),
    isError() {
      return this.status === REQUEST_STATUS.error
    },
    isLoading() {
      return this.status === REQUEST_STATUS.loading
    },
    printableAdmins() {
      return this.gmbAccount?.admins?.length > 0 ? this.gmbAccount.admins : []
    },
    printableNotificationTypes() {
      if (this.gmbAccount?.notifications?.notificationTypes?.length > 0) {
        return this.gmbAccount?.notifications?.notificationTypes.map(v => ({ notificationType: v }))
      }
      return []
    },
    tabs() {
      return [
        {
          label: this.$t('gmb.list.id.tabs.admin'),
          key: 'admin',
        },
        {
          label: this.$t('gmb.list.id.tabs.notification'),
          key: 'notification',
        },
      ]
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmb.list.id.breadcrumb'),
          route: {
            name: 'AccountsId',
          },
        },
      ]
    },
  },
  methods: {
    setCurrentTab(tab) {
      this.currentTab = tab
    },
    async loadNotifications() {
      this.status = REQUEST_STATUS.loading
      try {
        const {
          data: { results },
        } = await getAccountNotifications(this.currentClientId, this.$route.params.name)
        this.gmbAccount = results
        this.status = REQUEST_STATUS.success
      } catch (err) {
        this.status = REQUEST_STATUS.error
      }
    },
    async activateAndLoadNotifications() {
      this.status = REQUEST_STATUS.loading
      try {
        const {
          data: { results },
        } = await activateAndGetNotifications(this.currentClientId, this.$route.params.name)
        this.gmbAccount = results
        this.status = REQUEST_STATUS.success
      } catch (err) {
        this.status = REQUEST_STATUS.error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-accounts-id {
  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $gutter-mobile;

    &__label {
      color: $black-mine-shaft;
      font-weight: $font-weight-title;
    }
  }

  &__table {
    display: block;
    height: 100%;
    overflow-y: auto;
  }
}
</style>
